<template lang="pug">
  .data-streams-page
    .app-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .header.uo-header
      h1.nio-h1.text-primary-darker Data Streams
      NioButton(
        normal-primary
        @click="newDataStream"
      ) New Data Stream
    .no-data-streams(
      v-if="!loading && dataStreams && dataStreams.length === 0"
    )
      NioIconFramer(
        icon-name="display-list"
      )
      h3.nio-h3.text-primary-darker You have no data streams
      NioButton(
        normal-secondary
        @click="seeHow"
      ) Learn about data streams
    .data-streams(
      v-else-if="!loading"
      :class="{'free-version': appTier === 'free', 'editing': editingDataStream}"
    )
      NioSlatTable(
        v-if="columns && dataStreams"
        :items="dataStreams"
        :columns="columns"
        :header-modules="['count', 'sort']"
        :sort-options="sortOptions"
        :searchable-props="['name', 'id']"
        action="expand"
        @itemExpanded="expand($event)"
      )
        //- template(v-slot:footer-actions)
        //-   .free-version-footer(v-if="appTier === 'free'")
        //-     NioIconFramer(
        //-       iconName="display-list-warning"
        //-     )
        //-     h3.nio-h3.text-primary-darker You've reached data stream limit
        //-     p.nio-p.text-primary-dark Free tiers are ony allowed to create one data stream. Upgrade your app subscription to create more data streams.
        //-     NioButton(
        //-       normal-secondary
        //-       @click="upgrade"
        //-     ) Upgrade your App Tier
        //-   NioButton(v-else normal-primary-append iconName="utility-plus") New Data Stream
        template(v-slot:item-expanded="slotProps") 
          .display-row.display-table
            .display-column.description
              .nio-h7.text-primary-dark Description
              .nio-p.text-primary-dark(v-if="slotProps.item.content.description" v-sanitize.basic="slotProps.item.content.description") 
              .nio-p.text-primary-dark.empty(v-else) None provided
          .display-row.display-table
            .display-column.description
              .nio-h7.text-primary-dark Category
              .nio-p.text-primary-dark(v-if="slotProps.item.category" v-sanitize.basic="slotProps.item.category") 
              .nio-p.text-primary-dark.empty(v-else) None provided
          .display-row.display-table
            .display-column.description
              .nio-h7.text-primary-dark Image URL
              .nio-p.text-primary-dark(v-if="slotProps.item.content.icon" v-sanitize.basic="slotProps.item.content.icon") 
              .nio-p.text-primary-dark.empty(v-else) None provided
          .display-row.tags(v-if="slotProps.item.tags && slotProps.item.tags.length > 0")
            .nio-h7.text-primary-dark Tags
            .pills
              NioPill(
                tag
                v-for="tag of slotProps.item.tags"
              ) {{ tag }}
          .display-row.included-filters
            .nio-h7.text-primary-dark Included Filters
            .pills(v-if="getAppliedFilters(slotProps.item).length > 0")
              NioPill(
                tag
                v-for="filter of getAppliedFilters(slotProps.item)"
              ) {{ filter }}
            .nio-p.text-primary-dark.empty(v-else) No filters applied
          .display-row.included-filters
            .nio-h7.text-primary-dark Deduplication
            .deduplication(v-if="slotProps.item.data_rules.deduplication")
              .nio-p.text-primary-dark Deliver only unique records <span class="nio-bold">every {{ makeReadablePeriod(slotProps.item.data_rules.deduplication.period) }}</span> based on these data points:
                .pills
                  NioPill(
                    tag
                    v-for="columnName of slotProps.item.data_rules.deduplication.column_references[0].column_names"
                  ) {{ columnName }}
            .no-deduplication(v-else)
              .nio-p.text-primary-dark.empty Deliver all data points      
          .display-row.dataset
            .nio-h7.text-primary-dark Dataset Name
            .nio-p.text-primary-dark {{ getDatasetName(slotProps.item) }}
          .display-row.offers(v-if="slotProps.item.offers")
            .nio-h7.text-primary-dark Offers
            .offer-row(v-for="offer of slotProps.item.offers")
              .entry
                .label.nio-h7.text-primary-dark.empty Channel: 
                .value.nio-p.text-primary-dark {{ offer.channel === 'data_shop' ? 'Your Data Shop' : 'Data Stream Marketplace' }}
              .entry
                .label.nio-h7.text-primary-dark.empty Price (CPT): 
                .value.nio-p.text-primary-dark {{ formatCurrency(offer.pricing.micro_cents_usd) }}
          .actions
            NioButton.edit(
              v-if="computeStatus(slotProps.item) !== 'Inactive'"
              normal-secondary
              @click="edit(slotProps.item)"
            ) Edit Data Stream
            NioButton.deactivate(
              v-if="computeStatus(slotProps.item) !== 'Inactive'"
              caution-text
              @click="deactivate(slotProps.item)"
            ) Deactivate Data Stream
          //- .deactivating(v-if="deactivating && deactivateDataStreamId && deactivateDataStreamId === slotProps.item.id")
          //-   v-progress-circular.progress(size="80" indeterminate color="#1438F5")  
    NioDialog(
      v-model="confirmDeactivateDialog" 
    )
      ConfirmDeactivateDialog(
        @cancel="confirmDeactivateDialog = false"
        @confirm="confirmDeactivate"
      )     
    NioDialog(
      v-model="editingDataStream"
    )   
      EditDataStreamDialog(
        :data-stream="dataStreamToEdit"
        @cancel="editingDataStream = false; dataStreamToEdit = null"
        @save="updateDataStream($event)"
      )
    NioDialog(
      v-model="errorDialog" 
    )
      ErrorDialog(
        @close="errorDialog = false"
      )
</template>

<script>

import { sortOptions } from './sortOptions'
import ConfirmDeactivateDialog from './ConfirmDeactivateDialog'
import EditDataStreamDialog from './EditDataStreamDialog.vue'
import ErrorDialog from '@/shared/components/ErrorDialog'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import moment from 'moment'
import { formatCurrency } from '@/modules/helpers'

export default {
  components: { ConfirmDeactivateDialog, EditDataStreamDialog, ErrorDialog },
  data: () => ({
    dataStreams: null,
    deactivating: {},
    loading: true,
    columns: null,
    sortOptions: sortOptions,
    appTier: 'free',
    confirmDeactivateDialog: false,
    deactivateDataStream: null,
    datasets: [],
    editingDataStream: false,
    dataStreamToEdit: null,
    errorDialog: false
  }),	
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    makeReadablePeriod(period) {
      let str = period.replace('P', '')
      if (str.indexOf('D') > -1) {
        return `${str.replace('D', '')} days`
      } else if (str.indexOf('W') > -1) {
        return `${str.replace('W', '')} weeks`
      } else if (str.indexOf('M') > -1) {
        return `${str.replace('M', '')} months`
      }
    },
    openApiInit() {
      Promise.all([this.getDatasets(), this.getDataStreams()]).then(([datasets, dataStreams]) => {
        this.dataStreams = dataStreams.filter(dataStream => dataStream.data_rules.column_sets?.length > 0)
        this.datasets = datasets
        this.makeColumns()
        this.loading = false
      })
    },
    async getDataStreams() {
      return new Promise((resolve, reject) => {
        this.$nioOpenApi.get(`/data-stream/company/${this.nioUser.contextCompany}?per_page=1000&offset=0`).then(res => {
          resolve(res.data.records)
        })
      })        
    },
    getDatasets() {
      return new Promise((resolve,reject) => {
        this.$nioOpenApi.get('/datasets').then(res => {
          const datasets = res.data.records.filter(record => Object.keys(record.schema.properties).length > 0)
          resolve(datasets)
        })
      })
    },
    newDataStream() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'new'
      },"*")
    },
    getDatasetName(dataStream) {
      // TODO: Figure out how to handle multiple datasets in column_sets
      return this.datasets.find(dataset => dataset.id === dataStream.data_rules.column_sets[0].dataset_id)?.name || ''
    },
    seeHow() {
      window.open('https://kb.narrative.io/data-studio-for-sellers', '_blank')
    },
    computeId(item) {
      return `#${item.id}`
    },
    computeCreated(item) {
      return moment(item.created_at).format('MMM D, YYYY')
    },
    computeStatus(item) {
      return item.offers && item.offers.length > 0 ? 'Active' : 'Inactive'
    },
    computeCost(item) {
      let offer
      if (!item.offers) {
        return 'N/A'
      }
      if (item.offers.length === 1) {
        offer = item.offers[0]
      } else {
        offer = item.offers.find(offer => offer.channel === 'data_shop')
      }
      return this.formatCurrency(offer.pricing.micro_cents_usd)
    },
    formatCurrency(val) {
      return `${formatCurrency(val * 10 / 1000000)}`
    },
    getAppliedFilters(item) {
      const appliedFilters = []
      // TODO: figure out how to display multiple column sets here
      const columns = item.data_rules.column_sets[0].fields
      columns.forEach(column => {
        if (column.filter !== null && column.filter !== "include_all_values_including_nulls_filter") {
          appliedFilters.push(column.field)
        }
      })
      return appliedFilters
    },
    truncateString(str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },
    formatDate(date) {
      return `${date.getFullYear()}`
    },
    makeColumns() {
      this.columns = [
        {
          name: "slat",
          props: {
            title: 'name',
            subtitle: this.computeId
          }
        },
        { 
          name: 'status',
          label: 'Status',
          computed: this.computeStatus,
          addItemAsClass: true
        },
        {
          name: "cost",
          label: "Cost (CPT)",
          computed: this.computeCost
        },
        {
          name: "created",
          label: "Created",
          computed: this.computeCreated
        }
      ]
    },
    upgrade() {
      window.open('https://app.narrative.io/app/data-studio-for-sellers', '_blank')
    },
    edit(dataStream) {
      this.editingDataStream = true
      this.dataStreamToEdit = dataStream
    },
    async updateDataStream(model) {
      this.loading = true
      this.editingDataStream = false
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")

      let updatedDataStream = { ...this.dataStreamToEdit }
      updatedDataStream.name = model.name
      updatedDataStream.tags = model.tags && model.tags.length > 0 ? model.tags : null
      updatedDataStream.category = model.category && model.category.length > 0 ? model.category : null
      updatedDataStream.content.description = model.description
      updatedDataStream.content.icon = model.image && model.image.length > 0 ? model.image : null 
      this.$nioOpenApi.put(`/data-stream/${this.dataStreamToEdit.id}`, updatedDataStream).then(resp => {
        if (resp.status === 200) {
          this.dataStreams = null
          this.getDataStreams().then(dataStreams => {
            this.dataStreams = dataStreams.filter(dataStream => dataStream.data_rules.column_sets?.length > 0)
            this.loading = false
          })
        } else {
          this.loading = false
          this.errorDialog = true
        }
      }, err => {
        this.loading = false
        this.errorDialog = true
      })
    },
    deactivate(dataStream) {
      this.deactivateDataStream = dataStream
      this.confirmDeactivateDialog = true
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
    },
    confirmDeactivate() {
      this.confirmDeactivateDialog = false
      this.loading = true
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      const updatedDataStream = this.deactivateDataStream
      updatedDataStream.offers = null
      this.dataStreams = null
      this.$nioOpenApi.put(`/data-stream/${this.deactivateDataStream.id}`, updatedDataStream).then(res => {
        this.getDataStreams().then(dataStreams => {
          this.dataStreams = dataStreams.filter(dataStream => dataStream.data_rules.column_sets?.length > 0)
          this.loading = false
        })
      })
    },
    expand(dataStream) {
      this.dataStreamToEdit = dataStream
    }
  }
};
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.data-streams-page
  background-color: $c-white
  padding: 1.5rem
  .header
    display: flex
    justify-content: space-between
    align-items: flex-end
    position: relative
    margin-bottom: 1.5rem
    height: 2.25rem
    h1
      line-height: 1.75rem
    .nio-button
      position: absolute
      right: 0rem
  .no-data-streams, .free-version-footer
    padding: 9.6875rem 1.5rem 11.1875rem 1.5rem
    background-color: $c-canvas
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.75rem
    display: flex
    flex-direction: column
    align-items: center
    .nio-icon-framer
      margin-bottom: 1rem
    h3
      margin-bottom: 0.5rem
    p    
      margin-bottom: 2.5rem
  .data-streams.free-version
    padding: 0rem !important  
    ::v-deep .nio-slat-title
      max-width: 31.25rem
      white-space: pre-wrap !important
    ::v-deep .header-selected
      display: none   
    ::v-deep .nio-slat-table-actions
      padding: 0rem !important  
    .free-version-footer
      border: none
      border-radius: 0rem
      width: 100
    ::v-deep .nio-slat-content
      width: 100%
    ::v-deep .nio-slat-action
      display: none  
    ::v-deep td:not(.expanded-row)
      padding-top: 1.5rem
      padding-bottom: 1.5rem
    ::v-deep .column-status.value-Inactive .value
      color: $c-error
    ::v-deep .expanded-row
      border-bottom: 0.0625rem solid $c-primary-lighter
      position: relative
      padding: 2rem 1.5rem 2.5rem 1.5rem
      background-color: $c-canvas
      .empty
        opacity: 0.7
      .display-row
        margin-bottom: 1.5rem
        &:not(.display-table)
          display: flex
          flex-direction: column
          align-items: flex-start
          .nio-h7
            margin-bottom: 0.25rem
        &.display-table
          display: flex
          justify-content: space-between
          align-items: flex-start
          .display-column
            display: flex
            flex-direction: column
            align-items: flex-start
            flex-grow: 2
            margin-right: 1.5rem
            &.description
              // max-width: 22.5rem
              .nio-h7, .nio-p
                max-width: 100%
        .pills
          display: flex
          flex-wrap: wrap
          .nio-pill
            margin-right: 0.25rem
        &.offers
          .offer-row
            margin-bottom: 0.5rem
            .entry
              display: flex
              .value
                margin-left: 0.5rem
      .actions
        display: flex
        justify-content: space-between   
  .data-streams.editing
    height: 0rem      
    overflow: hidden
  ::v-deep .column-status.value-Stopped .nio-table-value
    color: $c-error  
  .deactivating
    width: 100%
    position: absolute
    .v-progress-circular
      left: 50%
      margin-left: -2.5rem
      top: 50%
      margin-top: -2.5rem
      z-index: 2
</style>