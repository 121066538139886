<template lang="pug">
  .edit-data-stream-dialog(ref="dialog")
    .header.step-header
      h2.nio-h2.text-primary-darker Edit Data Stream
    .description-container
      .name.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Name
          .description.nio-p.text-primary-dark The name of the data stream as displayed to customers.
        .filter-value
          NioTextField(
            v-model="model.name"
            :rules="[rules.required]"
            label="Data Stream Name"
            validate-on-blur
          )
      .description.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Description
          .description.nio-p.text-primary-dark Provide information about this data stream to customers. If your Data Shop is public, we'll add a JSON-LD product schema to help buyers discover your data stream on search engines.
        .filter-value
          NioTextarea(
            v-model="model.description"
            :rules="[rules.required]"
            :rows="3"
            placeholder="Data Stream Description"
            validate-on-blur
          )
      .image.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Image
          .description.nio-p.text-primary-dark Provide a product image to showcase the data stream.
        .filter-value
          NioTextField(
            v-model="model.image"
            label="Data Stream Image URL"
            validate-on-blur
          )
      .category.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Category
          .description.nio-p.text-primary-dark Provide an optional category for the data stream.
        .filter-value
          NioTextField(
            v-model="model.category"
            label="Data Stream Category"
          )
      .tags.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Tags
          .description.nio-p.text-primary-dark Add tags for discoverability on your sales channels.
        .filter-value
          NioTagsField(
            v-model="model.tags"
            label="Add Tags"
          )
    .actions
      NioButton(
        normal-secondary
        @click="cancel"
      ) Cancel
      NioButton(
        normal-primary
        :disabled="!valid"
        @click="save"
      ) Save
</template>

<script>

import TooltipsData from '@/modules/tooltipsData'

export default {
  props: {
    dataStream: { type: Object, required: true },
  },
  data: () => ({
    tooltips: TooltipsData.pages.new.steps.description,
    model: {
      name: null,
      image: null,
      description: null,
      tags: [],
      category: null
    },
    rules: {
      required: value => !!value || 'Required',
      maxLength: value => value && value.length < 256 || 'Maximum of 255 characters allowed'
    }
  }),
  computed: {
    valid() {
      return this.model.name && this.model.name.length > 0 && this.model.description && this.model.description.length > 0
    }
  },
  mounted() {
    this.initModel()
  },
  methods: {
    initModel() {
      this.model.name = this.dataStream.name
      if (this.dataStream.tags && this.dataStream.tags.length > 0) {
        this.model.tags = this.dataStream.tags
      }
      this.model.category = this.dataStream.category
      const content = this.dataStream.content
      if (content.description && content.description.length > 0) {
        this.model.description = content.description
      }
      if (content.icon) {
        this.model.image = content.icon
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.$emit('save', this.model)
    }
  },
  components: { }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.edit-data-stream-dialog
  padding: 1.5rem
  background-color: $c-white
  border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  display: flex
  flex-direction: column
  align-items: center
  .description-container
    width: 100%
    border-radius: 0.75rem
    border: 0.0625rem solid $c-primary-lighter
    overflow: hidden
    .nio-textarea
      width: 100%
      ::v-deep .v-input__slot
        border: 0.0625rem solid $c-primary-light
      ::v-deep textarea::placeholder
        color: $c-primary-dark
    .filter
      +nio-filter-header
      .title-description
        padding-right: 1.5rem
        .description
          max-width: 20rem
      .filter-value
        .nio-text-field, .nio-tags-field
          width: 100%
      &.slug .filter-value
        margin-top: -0.4375rem
    & > .filter + .filter
      border-top: 0.0625rem solid $c-primary-lighter
  .actions
    width: 100%
    display: flex
    justify-content: flex-end
    align-items: center
    margin-top: 1.5rem
    & > * + * 
      margin-left: 1rem  
</style>